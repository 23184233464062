<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card>
      <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card-title>
        {{ $t("Companie") }}
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="title" autofocus :label="$t('Nume')" :rules="inputRequired" />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="companyTypes"
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                multiple
                :items="appTypes"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"

import { EventBus } from "../../EventBus"

export default {
  components: {},
  data: () => ({
    recordId: "",
    appTypes: [],
    companyTypes: [],
    record: {},
    etag: "",
    show: false,
    title: ""
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    }
  },
  mounted() {
    axios.get('app_dictionary?where={"value":"appType"}').then(response => {
      this.$log("app_company reload", response)
      this.appTypes = response.data._items[0].content
      this.$log("appTypes ", this.appTypes)
    })
  },
  methods: {
    addData() {
      this.show = true
      this.recordId = 0
      this.title = ""
      this.companyTypes = []
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.title = e.name
      this.$log("etag ", e)
      axios.get("app_company/" + this.recordId).then(response => {
        this.$log("app_template", response)
        this.etag = response.data._etag
        this.companyTypes = response.data.appType
        this.$log("this.companyTypes", this.companyTypes)
      })
    },

    saveData() {
      this.$log("this.companyTypes", this.companyTypes)
      if (this.$refs.dataForm.validate()) {
        if (this.recordId === 0) {
          this.$log("file1 ", this.file)
          axios
            .post("app_company/", {
              name: this.title,
              appType: this.companyTypes
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          axios
            .put(
              "app_company/" + this.recordId,
              {
                name: this.title,
                appType: this.companyTypes
              },
              {
                headers: {
                  "If-Match": this.etag
                }
              }
            )
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
